






















































import Vue, { PropType } from 'vue'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon'

export default Vue.extend({
  model: {
    prop: 'show',
    event: 'input'
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default() {
        return this.$t('confirmation')
      }
    },
    bodyText: {
      type: String,
      required: false,
      default: ''
    },
    okText: {
      type: String,
      required: false,
      default() {
        return this.$t('yes')
      }
    },
    okIcon: {
      type: Object,
      default: () => null
    },
    cancelText: {
      type: String,
      required: false,
      default() {
        return this.$t('cancel')
      }
    },
    cancelIcon: {
      type: Object,
      default: () => null
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    okVariant: {
      type: String,
      required: false,
      default: 'primary'
    },
    okOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelVariant: {
      type: String,
      required: false,
      default: 'link'
    },
    cancelOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    icon: {
      type: Object as PropType<FontAwesomeIcon>,
      required: false,
      default: null
    },
    iconClasses: {
      type: String,
      required: false,
      default: ''
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false
    },
    hideHeaderClose: {
      type: Boolean,
      default: false
    },
    cancelCallback: {
      type: Function,
      required: false,
      default: null
    },
    fullWidthButtonsOnMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(value: boolean) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  },
  methods: {
    onCancel() {
      if (this.cancelCallback) {
        this.cancelCallback()
        return
      }
      this.$emit('cancel')
    },
    handleHide() {
      this.$emit('cancel')
      this.$emit('hide')
    }
  }
})
