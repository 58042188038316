
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import qs from 'qs'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import WantedBadge from '~/components/car/classifieds/badges/WantedBadge.vue'
import ForRentBadge from '~/components/car/classifieds/badges/ForRentBadge.vue'
import DamagedBadge from '~/components/car/classifieds/badges/DamagedBadge.vue'
import NewBadge from '~/components/car/classifieds/badges/NewBadge.vue'
import CClassifiedFavoriteButton from '~/components/shared/configurable/classified/bucket/favorite/CClassifiedFavoriteButton.vue'
import DealersOnlyBadge from '~/components/car/classifieds/badges/DealersOnlyBadge.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import { CategoryId } from '~/models/category/types'
import RequestsWorkBadge from '~/components/car/classifieds/badges/RequestsWorkBadge.vue'
import RequestsEmployeeBadge from '~/components/car/classifieds/badges/RequestsEmployeeBadge.vue'
import CompareService from '~/services/compare/CompareService'
import { OfferType } from '~/models/classified/types'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { sellerIsOfTypeDealer } from '~/utils/user'
import ClassifiedSearchPageUrlService from '~/services/classified-search-page-url/ClassifiedSearchPageUrlService'
import LeasingOption from '~/components/car/classifieds/search/results/rows/main/partials/LeasingOption.vue'
import {
  LEASING_DOWNPAYMENT_PARAM_NAME,
  LEASING_DURATION_PARAM_NAME,
  LEASING_LEASE_PARAM_NAME,
  LEASING_MILEAGE_PARAM_NAME,
  LEASING_WITH_VAT_PARAM_NAME
} from '~/constants/finance/leasing'
import {
  INSTALLMENTS_DOWNPAYMENT_PARAM_NAME,
  INSTALLMENTS_NUM_OF_INSTALLMENTS_PARAM_NAME
} from '~/constants/finance/installments'
import PaidClassifiedBadge from '~/components/car/classifieds/badges/PaidClassifiedBadge.vue'
import PrivateSellerBadge from '~/components/shared/classified/view/PrivateSellerBadge.vue'
import { toCamelCase } from '~/utils/object'
import LeasingBadge from '~/components/car/classifieds/badges/LeasingBadge.vue'
import InstallmentsBadge from '~/components/car/classifieds/badges/InstallmentsBadge.vue'
import { APP_NS } from '~/store/modules/shared/app/state'
import SearchService from '~/services/search/SearchService'
import { solidKeyFeaturesIcons } from '~/constants/classified/key-feature/icon/solid'
import { solidFallbackIcon } from '~/constants/classified/key-feature/icon'
import { Promotion } from '~/models/classified/promoted'
import { SubscriptionPlanName } from '~/services/logistics/StripeService'
import RentalPrice from '~/components/car/classifieds/search/results/rows/main/partials/RentalPrice.vue'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'

export default Vue.extend({
  components: {
    DeletedDate: () => import('./partials/DeletedDate'),
    CensoredOverlay: () => import('./partials/CensoredOverlay.vue'),
    Actions: () =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/Actions/Actions.vue'
      ),
    OneClickAutoTouchBadge: () =>
      import('~/components/car/classifieds/badges/OneClickAutoTouchBadge.vue'),
    PromotedBadge: () =>
      import('~/components/car/classifieds/badges/PromotedBadge.vue'),
    CClassifiedFavoriteButton,
    DealersOnlyBadge,
    WantedBadge,
    ForRentBadge,
    DamagedBadge,
    NewBadge,
    LeasingOption,
    PaidClassifiedBadge,
    LeasingBadge,
    InstallmentsBadge,
    PrivateSellerBadge,
    CBadge,
    RentalPrice,
    AuditedBadge: () =>
      import('~/components/car/classifieds/badges/AuditedBadge.vue')
  },
  props: {
    classified: {
      type: Object,
      required: true,
      default: null
    },
    index: {
      type: Number,
      required: true
    },
    rowsExtraQueryParams: {
      type: Object,
      required: false,
      default: null
    },
    lazyLoadImage: {
      type: Boolean,
      required: false,
      default: true
    },
    rowsTarget: {
      type: String,
      required: false,
      default: '_self'
    },
    showAddress: {
      type: Boolean,
      required: false,
      default: true
    },
    showModificationDate: {
      type: Boolean,
      required: false,
      default: true
    },
    showActionDisableButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    socialFooter: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    disabledThumbNavigation: {
      type: Boolean,
      default: false
    },
    inMap: {
      type: Boolean,
      default: false,
      required: false
    },
    isRogue: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      currentRoute: state => state.currentRoute,
      isCompareButtonVisible: state =>
        state.config.settings.isCompareButtonVisible,
      isUserSearch: state => state.userProfile
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isAdminSearch: state => state.config.settings.isAdminSearch,
      isDeletedSearch: state => state.config.settings.isDeletedSearch
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    ...mapGetters(USER_NS, {
      isAnonymous: 'isAnon',
      userIsAdmin: 'isAdmin'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getCurrentCategoryId: 'getCurrentCategoryId'
    }),
    ...mapGetters(PAGE_NS, {
      consentIsConfirmed: 'consentIsConfirmed'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc',
      isMobile: 'isMobile'
    }),
    searchPageUrlService(): ClassifiedSearchPageUrlService {
      const searchService = this.$dep(SearchService)
      return searchService.getSearchPageUrlService()
    },
    classifiedURL(): string {
      if (this.isPlot) {
        return this.classified.seo_url_plot || '/' + this.classified.id
      }
      return this.searchPageUrlService.getClassifiedUrl(this.classified)
    },
    isHidden() {
      return this.classified.states && this.classified.states.is_hidden
    },
    isNonPublic() {
      return this.classified.states && this.classified.states.is_non_public
    },
    isRemoved() {
      return this.classified.states && this.classified.states.is_removed
    },
    isDeleted() {
      return this.classified.states && this.classified.states.is_deleted
    },
    isPromoted() {
      return this.classified.from_promotion || this.promotedInMyClassifieds
    },
    showPromoUser() {
      return (
        (this.isPromoted || this.sellerInPremiumPlan) && this.sellerIsDealer
      )
    },
    isPaidOneClickAutoTouchEnabled() {
      return (
        this.userIsAdmin &&
        this.classified?.paidFeatures?.isPaidOneClickAutoTouchEnabled
      )
    },
    isPromotionEnabled() {
      return (
        this.userIsAdmin && this.classified?.paidFeatures?.isPromotionEnabled
      )
    },
    sellerInPremiumPlan() {
      return (
        this.classified.seller?.subscription_tier ===
        SubscriptionPlanName.PREMIUM
      )
    },
    sellerIsDealer() {
      if (this.classified.seller) {
        return sellerIsOfTypeDealer(this.classified.seller)
      }
      return false
    },
    promotedInMyClassifieds() {
      const query = this.currentRoute.query
      return (
        this.userCanSeeActions &&
        (query.myclassifieds ||
          query.promotion ||
          query['promotion-tier'] ||
          this.userIsAdmin) &&
        this.classified.promotion &&
        this.classified.promotion.activePromotion &&
        this.classified.promotion.activePromotion.isPromoted
      )
    },
    currentTier() {
      if (this.promotedInMyClassifieds) {
        return this.classified.promotion.activePromotion.tier
      }
      return ''
    },
    userCanSeeActions() {
      if (this.eshopOnboarding) {
        return false
      }
      return this.classified.own || this.userIsAdmin
    },
    isPaidClassified() {
      return this.classified.paid_classified || false
    },
    requiresPayment() {
      return (
        (this.classified.paid_classified &&
          (this.classified.paid_classified.requires_payment ||
            this.classified.paid_classified.has_payment_failure)) ||
        false
      )
    },
    rowClasses() {
      const classes = []
      if (this.isDeletedSearch) {
        if (!this.isVehicle) {
          classes.push('deleted-search-parts-xyma-bg')
        }
        return classes
      }
      const { states } = this.classified
      if (states) {
        if (states.is_removed) {
          classes.push('!tw-bg-red-200')
        } else if (states.is_unlisted) {
          classes.push('!tw-bg-yellow-50')
        } else if (states.is_deleted) {
          classes.push('!tw-bg-red-100')
        } else if (states.is_non_public) {
          classes.push('!tw-bg-yellow-200')
        } else if (states.is_hidden) {
          classes.push('!tw-bg-yellow-100')
        } else if (
          (this.isAdminSearch || (this.isUserSearch && this.userIsAdmin)) &&
          !this.isVehicle
        ) {
          classes.push('admin-search-parts-xyma-bg')
        }
      }
      return classes
    },
    isCensored() {
      const { consent } = this.classified
      return consent && !this.consentIsConfirmed(consent.name)
    },
    isDamaged() {
      return this.classified.damaged || this.classified.crashed
    },
    isNew() {
      return this.classified.is_new
    },
    isWanted() {
      return this.classified.offertype === OfferType.WANTED
    },
    isForRent() {
      return this.classified.offertype === OfferType.RENT
    },
    isDeal() {
      return Boolean(this.classified.discount)
    },
    isVehicle() {
      return this.classified.category_ids.includes(CategoryId.VEHICLES)
    },
    isJob() {
      return this.classified.category_ids.includes(CategoryId.JOBS)
    },
    hasForeignNumberPlate() {
      return this.classified.foreign_number_plate
    },
    isJobWanted() {
      return this.classified.category_ids.includes(CategoryId.JOBS_OFFERED)
    },
    isJobOffered() {
      return this.classified.category_ids.includes(CategoryId.JOBS_WANTED)
    },
    isWantedParts() {
      return this.classified.category_ids.includes(CategoryId.WANTED_PARTS)
    },
    eshopOnboarding() {
      return this.$route.query.eshop_onboarding
    },
    href() {
      if (this.eshopOnboarding) {
        const legacyUrlService = this.$dep(LegacyUrlService)
        const url = legacyUrlService.getCompatibilityEditUrl(
          this.classified.category_ids,
          this.classified.id
        )

        return `${url}?page=eshop`
      }

      let href = `${this.classifiedURL}`
      let financeQueryParams = {}
      let leasingQueryParams = {}
      let rentalQueryParams = {}

      const { installment, leasing } = this.classified

      if (installment) {
        const { installments, downpayment } = this.currentRoute.query
        financeQueryParams = {
          [INSTALLMENTS_NUM_OF_INSTALLMENTS_PARAM_NAME]: installments,
          [INSTALLMENTS_DOWNPAYMENT_PARAM_NAME]: downpayment
        }
      }
      if (leasing && leasing.option) {
        leasingQueryParams = {}
        const { option } = leasing
        const {
          with_vat: withVat,
          duration: { value: durationValue },
          mileage: { value: mileageValue },
          downpayment: { value: downpaymentValue },
          lease: { value: leaseValue }
        } = option
        leasingQueryParams = {
          [LEASING_WITH_VAT_PARAM_NAME]: withVat,
          [LEASING_DURATION_PARAM_NAME]: durationValue,
          [LEASING_MILEAGE_PARAM_NAME]: mileageValue,
          [LEASING_DOWNPAYMENT_PARAM_NAME]: downpaymentValue,
          [LEASING_LEASE_PARAM_NAME]: leaseValue
        }
      }
      if (this.rentalInfo) {
        rentalQueryParams = {
          'rental_period-from': this.currentRoute.query['rental_period-from'],
          'rental_period-to': this.currentRoute.query['rental_period-to']
        }
      }
      const extraQueryParams = {
        ...this.rowsExtraQueryParams,
        ...financeQueryParams,
        ...leasingQueryParams,
        ...rentalQueryParams
      }
      const stringifiedExtraQueryParams = qs.stringify(extraQueryParams, {
        arrayFormat: 'repeat',
        encode: false,
        skipNulls: true
      })
      if (stringifiedExtraQueryParams) {
        href = `${href}?${stringifiedExtraQueryParams}`
      }
      return href
    },
    processedKeyFeatures() {
      return this.classified.key_features.map(feature => {
        return {
          ...feature,
          ...{ icon: this.getKeyFeatureIcon(feature.key) }
        }
      })
    },
    stateBadgeComponent() {
      if (this.isJob) {
        if (this.isJobWanted) {
          return RequestsWorkBadge
        } else if (this.isJobOffered) {
          return RequestsEmployeeBadge
        }
      } else if (this.isWanted) {
        return WantedBadge
      } else if (this.isForRent && !this.hasLeasing) {
        return ForRentBadge
      } else if (this.isDamaged) {
        return DamagedBadge
      } else if (this.classified.is_new) {
        return NewBadge
      }

      return null
    },
    priceRelatedText() {
      if (this.hasForeignNumberPlate) {
        return this.$t('foreign number plate')
      } else if (this.classified.without_vat) {
        return `${this.$t('without')} ${this.$t('vat').toUpperCase()}`
      }

      return null
    },
    classifiedBackgroundJobs() {
      if (
        this.classified.jobs &&
        (this.classified.jobs.succeed.length ||
          this.classified.jobs.failed.length)
      ) {
        return toCamelCase(this.classified.jobs)
      }
      return null
    },
    hasInstallments(): boolean {
      return this.classified.has_finance
    },
    isInterestZero() {
      if (this.classified.has_finance) {
        return this.classified.finance_options.interest === 0
      }
      return false
    },
    hasLeasing(): boolean {
      return this.classified.has_leasing
    },
    hasSomeFinanceMethodEnabled(): boolean {
      const { classified } = this
      return Boolean(
        (classified.installment && classified.finance_options) ||
          (classified.leasing && classified.leasing.option)
      )
    },
    rentalInfo() {
      return this.classified.rental
    },
    hasRentalInfo(): boolean {
      return Boolean(this.rentalInfo)
    },
    showMainPrice(): boolean {
      // do not show for rental cars
      return !this.hasRentalInfo
    },
    isRental(): boolean {
      return this.classified.category_ids.includes(CategoryId.RENTALS)
    },
    showSuffix() {
      return (
        (this.isRental && !this.hasRentalInfo) ||
        (this.isPlot && this.classified.offertype === 'booking')
      )
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_VIEW_NS, {
      navigateToClassifiedView: 'navigateToClassifiedView'
    }),
    getKeyFeatureIcon(key) {
      if (this.isWantedParts) {
        return solidFallbackIcon
      }
      return solidKeyFeaturesIcons[key] || solidFallbackIcon
    },
    triggerThumbClick(e) {
      if (!this.eshopOnboarding) {
        e.preventDefault()
        e.stopPropagation()
      } else {
        return
      }
      if (this.$refs.thumb) {
        this.$refs.thumb.imgClicked(e)
      }
    },
    thumbClicked(evt, thumbData) {
      if (!this.disabledThumbNavigation && !this.eshopOnboarding) {
        evt.preventDefault()
        evt.stopPropagation()
        this.goToClassifiedView(evt, thumbData)
      } else {
        this.$emit('thumb-clicked', { e: evt, thumbData })
      }
    },
    messageAlertClicked(e) {
      e.goToMessages = true
      this.triggerThumbClick(e)
    },
    goToClassifiedView(evt, refData = {}) {
      const { classified } = this

      const refs = {
        categoryIds: refData.categoryIds || classified.category_ids,
        id: refData.id || classified.id,
        isParked: refData.isParked || classified.is_parked,
        thumb: refData.thumb || classified.thumb,
        title: refData.title || classified.title,
        price: refData.price || classified.price,
        salary: refData.salary || classified.salary,
        payment: refData.payment || classified.payment,
        thumbIndex: refData.thumbIndex || 0,
        offertype: refData.offertype || classified.offertype,
        hasPhotos: refData.hasPhotos || classified.has_photos,
        thumbsLength:
          refData.thumbsLength ||
          (classified.thumbs ? classified.thumbs.count : 0),
        externalLink: refData.externalLink || classified.external_link,
        discount: classified.discount,
        goToMessagesOnLoad: false
      }

      if (refs.hasPhotos && classified.has_360photos) {
        // first index is always the 360 photo preview, so:
        refs.thumbIndex++
      }

      if (evt && evt.goToMessages) {
        refs.goToMessagesOnLoad = evt.goToMessages
      }
      this.navigateToClassifiedView({
        evt,
        url: this.href,
        refs,
        setSp: true
      })
    },
    async setCompare(classifiedId: number, refName: string) {
      this.$analytics.recordEvent({
        namespace: 'n_classifieds_search_compare_button',
        action: 'click',
        label: this.isPc ? 'desktop' : 'mobile'
      })

      const compareService = this.$dep(CompareService)
      try {
        await compareService.setComparedClassified(
          this.getCurrentCategoryId,
          classifiedId
        )
        this.$refs[refName].displayPopover(classifiedId)
      } catch (error) {
        this.$logger.captureError(error)
      }
    },
    onSetActivePromotion(promo: Promotion) {
      this.$emit('set-active-promotion', {
        classifiedId: this.classified.id,
        promo
      })
    }
  }
})
