var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title-container search-results-title-container",class:{
      'has-title': _vm.longTitle.length && !_vm.settings.hideSubtitle && _vm.isPc,
      comparison: _vm.hasComparisonButton
    }},[_c('div',{staticClass:"tw-flex tw-items-center text-and-sprite"},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-items-center"},[(_vm.isCar)?_c('CCategoryIcon',{staticClass:"!tw-mr-1 !tw-ml-0 tw-text-primary title-icon md:tw-inline-flex tw-hidden",attrs:{"type":_vm.searchType,"category-ids":_vm.categoryIds}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-items-center page-title-container",class:{
            black: _vm.inBlackFridaySearch,
            'for-car': _vm.isCar
          }},[(_vm.shortTitle && _vm.shortTitle.length > 0)?_c('h1',{staticClass:"page-title tw-flex tw-items-center"},_vm._l((_vm.shortTitle),function(st,index){return _c('span',{key:index,staticClass:"title-part"},[_vm._v("\n              "+_vm._s(st.value)+"\n              "),(index < _vm.shortTitle.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()])}),0):(_vm.longTitle && _vm.longTitle.length > 0)?_c('h1',{staticClass:"page-title"},[_vm._v("\n            "+_vm._s(_vm.longTitle[0].value)+"\n          ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"total-results-num-text"},[_vm._v("\n            ("+_vm._s(_vm.totalResultsText)+")\n          ")])])],1),_vm._v(" "),_c('div',{staticClass:"title-buttons-container tw-hidden lg:tw-flex"},[(_vm.settings.isMapSearchBtnVisible && _vm.mapSearchUrl && !_vm.isMobile)?_c('ShowOnMapButton'):_vm._e(),_vm._v(" "),(_vm.inPlotSearch && !_vm.isPlot)?_c('PlotButton'):_vm._e(),_vm._v(" "),(_vm.canBeSaved)?_c('SaveSearchButton',{staticClass:"title-button tw-ml-2"}):_vm._e(),_vm._v(" "),_c('ShareButton',{staticClass:"title-button tw-ml-2 tw-mr-2 tw-hidden md:tw-block",attrs:{"description":_vm.title,"title":_vm.$t('i found these interesting results in car.gr')}})],1)]),_vm._v(" "),_c('span',{staticClass:"title-btns !tw-flex lg:!tw-hidden"},[(_vm.inPlotSearch && !_vm.isPlot)?_c('PlotButton'):_vm._e(),_vm._v(" "),(
          _vm.settings.isMapSearchBtnVisible &&
            _vm.mapSearchUrl &&
            _vm.isMobile &&
            !_vm.hideMobileMapBtn
        )?_c('ShowOnMapButton',{attrs:{"only-icon-on-mobile":""}}):_vm._e(),_vm._v(" "),(!_vm.showSellers && !_vm.inWantedPartsSearch)?_c('ViewTypeSelector'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }