

















































































































































































































































import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'
import RowMixin from './RowMixin'
import Title from './partials/Title'
import Location from './gallery/Location'
import KeyFeatures from './gallery/KeyFeatures'
import LastModified from './gallery/LastModified'
import Category from '~/components/car/classifieds/search/results/rows/main/partials/Category.vue'
import {
  faEnvelope,
  faCaretDown,
  faCog
} from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import PromoUser from '~/components/car/classifieds/search/results/rows/main/partials/PromoUser.vue'
import CBadge360 from '~/components/shared/configurable/badge/CBadge360.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import { defineComponentTranslations } from '~/utils/i18n'
import { MessageStatus } from '~/models/messages/messages'
import SearchBucketService from '~/services/search-bucket/SearchBucketService'
import { handleError } from '~/utils/http'
import { AxiosError } from 'axios'
import MakeModel from '~/components/car/wanted-parts/MakeModel.vue'
import { Classified } from '~/models/classified/types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { SET_ACTIONS_ARE_COLLAPSED } from '~/store/modules/shared/classifieds/search/mutation-types'
import { mapMutations } from 'vuex'
import { defaultSanitizeHtmlOptions } from '~/constants/sanitization'
import sanitizeHtml from 'sanitize-html'

export default defineComponent({
  components: {
    CBadge360,
    BackgroundJobsBadge: () =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/BackgroundJobs/BackgroundJobsBadge.vue'
      ),
    PaidClassifiedInfo: () =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/PaidInfo/PaidClassifiedInfo.vue'
      ),
    PromoUser,
    LastModified,
    Title,
    KeyFeatures,
    Category,
    Location,
    CTransitionExpand,
    MakeModel,
    CContactFormModal: () =>
      import(
        '~/components/shared/configurable/contact-form-modal/CContactFormModal.vue'
      )
  },
  mixins: [RowMixin],
  data() {
    return {
      isContactFormModalVisible: false,
      isSeen: this.classified.is_seen,
      isLoading: false,
      actionsOpen: false
    }
  },
  computed: {
    icons() {
      return {
        envelope: faEnvelope,
        eye: faEye,
        cog: faCog,
        caretDown: faCaretDown
      }
    },
    shouldShowFooter() {
      return (
        (this.showAddress || this.showModificationDate) &&
        (this.classified.address ||
          this.classified.address_long ||
          this.classified.modified)
      )
    },
    messageButtonVariant() {
      const statusToVariantMap = {
        [MessageStatus.HAS_UNREAD_MESSAGES]: 'orange',
        [MessageStatus.HAS_READ_MESSAGES]: 'primary'
      }
      return statusToVariantMap[this.classified.message_status] || 'neutral'
    },
    noMessages() {
      return this.classified.message_status === MessageStatus.NO_MESSAGES
    },
    sawButtonVariant() {
      return this.isSeen ? 'primary' : 'neutral'
    },
    firstMakeModel() {
      return (
        this.classified?.for_makemodels?.[0] || {
          make: { id: 0, name: '' },
          model: {
            name: ''
          }
        }
      )
    },
    descriptiveTitle() {
      return sanitizeHtml(
        this.classified.descriptive_title,
        defaultSanitizeHtmlOptions
      )
    }
  },
  methods: {
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setActionsAreCollapsed: SET_ACTIONS_ARE_COLLAPSED
    }),
    async handleSeen() {
      this.isLoading = true
      const searchBucketService = this.$dep(SearchBucketService)
      try {
        if (this.isSeen) {
          await searchBucketService.removeSeenFromBucket(this.classified.id)
          this.isSeen = false
        } else {
          await searchBucketService.addToSeenBucket(this.classified.id)
          this.isSeen = true
        }
      } catch (error) {
        handleError(
          error as AxiosError,
          this.$snackbar,
          this.$logger,
          this.$t.bind(this)
        )
      } finally {
        this.isLoading = false
      }
    },
    toggleActionButtons() {
      this.actionsOpen = !this.actionsOpen
      if (this.actionsOpen) {
        this.setActionsAreCollapsed(true)
      }
    },
    handleClassifiedClick(evt: MouseEvent, classified: Classified) {
      const refs = {
        id: classified.id,
        isParked: classified.is_parked,
        title: classified.title,
        externalLink: null
      }

      this.navigateToClassifiedView({
        evt,
        url: classified.seo_url,
        setSp: true,
        refs
      })
    }
  },
  i18n: defineComponentTranslations({
    saw_it: {
      en: 'Saw it',
      el: 'Το είδα'
    }
  })
})
