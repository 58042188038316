





import { defineComponent, PropType } from '@nuxtjs/composition-api'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { BadgeSize } from '~/models/app/badge'

export default defineComponent({
  components: { CBadge },
  props: {
    size: {
      type: String as PropType<BadgeSize>,
      default: 'md'
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
